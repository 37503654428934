import { toast } from "react-toastify";
import { isEmpty } from "../utils/Util";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { setCredentials } from "../features/auth/authSlice";

export async function EverestApi(query, queryBody) {
  const url = `${process.env.REACT_APP_EVEREST_URL}api/Query`;

  const username = `${process.env.REACT_APP_EVEREST_USERNAME}`;
  const password = `${process.env.REACT_APP_EVEREST_PASSWORD}`;
  const credentials = btoa(`${username}:${password}`);

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    Accept: "application/json",
    Authorization: `Basic ${credentials}`,
  };
  try {
    const response = await axios.post(url, { query, queryBody }, { headers });
    return response.data;
  } catch (error) {
    alert(error);
  }
}

export async function TargetPayApi(
  query,
  data = null,
  method = "post",
  resource = "PerseusPay",
  returnError = false
) {
  let url = `${process.env.REACT_APP_BASE_URL}${resource}/${query}`;
  let apiKey = sessionStorage.getItem("nonce");
  let header;
  if (!isEmpty(apiKey)) {
    header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-API-KEY": apiKey,
      AppAgent: "TPFrontEnd",
    };
  } else {
    header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      AppAgent: "TPFrontEnd",
    };
  }
  const config = {
    headers: header,
    url: url,
    method: method,
    data: data,
    params: method == "post" ? null : data,
  };

  try {
    let response = await axios(config);
    return response.data;
  } catch (error) {
    if (error.message.toLowerCase().includes("401")) {
      const refresh_token = localStorage.getItem("refresh_token");
      const success: boolean = await GetRefreshToken(refresh_token);
      if (success) {
        // Call the function again which gave 401-Unauthorized exception with new access token
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          "access_token"
        )}`;
        let response = await axios(config);
        return response.data;
      } else {
        // return to Login
        window.location.href = "/login";
      }
    } else {
      let e = error.message;
      if (e.toLowerCase().includes("network error")) e = "Network Error...";
      toast.dismiss();
      if (!query.toLowerCase().includes("getvaultedcardid")) {
        toast.error("Error: " + e, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: true,
        });
      }
    }
    if (returnError) return error;
  }
}

export async function DealerProfile(key: any) {
  let token = localStorage.getItem("access_token");
  if (isEmpty(token)) return null;
  let JWTDeCode: any = jwt_decode(token);
  return JSON.parse(JWTDeCode.profile)[key];
}

export function JWTDecode(token: any, key: any) {
  if (isEmpty(token)) return null;
  let JWTDeCode: any = jwt_decode(token);
  let val = JWTDeCode[key];
  if (isEmpty(val)) return null;
  return val;
}

export function ValidatePhoneNumber(phoneNumber: string) {
  phoneNumber = phoneNumber.replace("+1", "");
  let phone = phoneNumber.replace(/[^0-9a-z]/gi, "");
  const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return regex.test(phone);
}

export function isValidUSPhoneNo(phoneNo: string) {
  // Regular expression to match the format "+1 (999) 999 9999"
  const phoneRegex = /^\+\d{1}\s\(\d{3}\)\s\d{3}\s\d{4}$/;
  return phoneRegex.test(phoneNo);
}

export function formatUSPhoneNo(phoneNo: string) {
  // Remove all non-digit characters from the phone number
  const cleanedPhoneNo = phoneNo.replace(/\D/g, "");

  // Format the phone number into the desired format
  const formattedPhoneNo = `+1 (${cleanedPhoneNo.substring(
    0,
    3
  )}) ${cleanedPhoneNo.substring(3, 6)} ${cleanedPhoneNo.substring(6)}`;
  return formattedPhoneNo;
}

export function ValidateEmail(email) {
  let validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return email.match(validRegex);
}

export function GetDealerPlatformFeeOption() {
  const storedValue = localStorage.getItem("platformFee_date_enabled");
  const isPlatformFeeDateEnabled =
    storedValue !== "null" && storedValue !== "" && storedValue !== null;

  return isPlatformFeeDateEnabled;
}

export function GetDealerPlatformFeeFlag() {
  const storedValue = localStorage.getItem("platformFee_flag_enabled");
  const isPlatformFeeFlagEnabled = storedValue !== "false";

  return isPlatformFeeFlagEnabled;
}

export async function GetDealerSettings(dealerId: number) {
  let _dealerId = await DealerProfile("dealerId");

  localStorage.setItem("platformFee_flag_enabled", "false");
  localStorage.setItem("platformFee_date_enabled", null);

  if (dealerId > 0) {
    _dealerId = dealerId;
  }
  const res = await TargetPayApi(
    `GetDealerSettings?dealerId=${_dealerId}`,
    null,
    "get"
  );

  if (!isEmpty(res)) {
    localStorage.setItem("platformFee_flag_enabled", res.isPlatformFeeEnabled);
    localStorage.setItem(
      "platformFee_date_enabled",
      res.platformFeeDateEnabled
    );
  }
}

// Get Payment Methods
export async function GetPaymentMethods() {
  const response = await TargetPayApi(`GetPaymentMethods`, null, "get");
  return response;
}

// Get Dealer Payment Methods
export async function GetDealerPaymentMethods(dealerId: any, storeId: any) {
  const response = await TargetPayApi(
    `GetDealerPaymentMethods?dealerId=${dealerId}&storeId=${storeId}`,
    null,
    "get"
  );
  return response;
}

export async function GetRefreshToken(refresh_token: any): Promise<boolean> {
  let url = `${process.env.REACT_APP_BASE_URL}Account/refreshToken`;
  try {
    const response = await axios.post(url, {
      RefreshToken: refresh_token,
    });

    if (response.data != null) {
      setCredentials({
        access_token: response.data.access_token,
        expires_in: response.data.expires_in,
        refresh_token: response.data.refresh_token,
        token_type: response.data.token_type,
        scope: response.data.scope,
      });
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      localStorage.setItem("onboardDealer_token", response.data.access_token);

      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}
